
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
    Swal,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      traineeList: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableData: [
        {
          id: 1,
          photo: " ",
          ref_no: "SEIP-AEOSIB-ABC-WF-N03-001",
          name: "MOHAMMAD NABIRUL ISLAM",
          phone: "01878900862",
          nid: "510232100",
          bcn: "19861591906025850",
          birth_date: "10-01-1986",
          reg_no: "2600001648",
          gender: "Male",
        },
        //{ id: 2 },
      ],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Photo",
          key: "photo",
          sortable: true,
        },
        {
          name: "Ref No.",
          key: "ref_no",
          sortable: true,
          width: "170px",
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
          width: "200px",
        },
        {
          name: "NID",
          key: "nid",
          sortable: true,
        },
        {
          name: "BCN",
          key: "bcn",
          sortable: true,
          width: "100px",
        },
        {
          name: "Reg No#",
          key: "reg_no",
          sortable: true,
          width: "170px",
        },
        {
          name: "Birth Date",
          key: "birth_date",
          sortable: true,
          width: "150px",
        },
        {
          name: "Gender",
          key: "gender",
          sortable: true,
        },
        {
          name: "Mobile",
          key: "phone",
          sortable: true,
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
    await this.getTraineeList();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTraineeList() {
      await ApiService.get("resource/traineelist")
        .then((response) => {
          this.traineeList = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      if (Object.keys(this.traineeList).length === 0) {
        console.log("No Trainee List");
        Swal.fire({
          title: "Loading Data..",
          text: "Please Wait While the Data is Loading",
          icon: "info",
          showCancelButton: false,
          showConfirmButton: false,
        });
        this.getTraineeList().then(() => {
          Swal.close();
          this.showCourseNotice = true;
        });
      } else {
        this.showCourseNotice = true;
      }
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      web_url: Yup.string().required().label("Web Url"),
      postcode: Yup.string().required().max(4).label("Postcode"),
      registration_number: Yup.string().required().label("Registration Number"),
      registration_authority: Yup.string()
        .required()
        .label("Registration Authority"),
      telephone: Yup.number().required().min(11).label("Telephone"),
      entity_name: Yup.string().required().label("Name"),
      entity_short_name: Yup.string().required().label("Short Name"),

      employeeEmail: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      //presentDistrict: Yup.string().required().label("Present District"),
      name: Yup.string().required().label("Name"),
      designation: Yup.string().required().label("Designation"),
      gender: Yup.string().required().label("Gender"),
      mobile: Yup.number().required().min(11).label("Mobile"),
      dob: Yup.string().required().label("Date of Birth"),

      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .label("Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password didn't match!")
        .label("Confirm Password"),
    });
    return {
      AssociationSchema,
    };
  },
});
